<div *ngIf="isLoading$ | async">
  <tools-risk-insights-loading></tools-risk-insights-loading>
</div>
<div class="tw-mt-4" *ngIf="!(isLoading$ | async) && !dataSource.data.length">
  <bit-no-items [icon]="noItemsIcon" class="tw-text-main">
    <ng-container slot="title">
      <h2 class="tw-font-semibold mt-4">
        {{ "noAppsInOrgTitle" | i18n: organization?.name }}
      </h2>
    </ng-container>
    <ng-container slot="description">
      <div class="tw-flex tw-flex-col tw-mb-2">
        <span class="tw-text-muted">
          {{ "noAppsInOrgDescription" | i18n }}
        </span>
        <a class="text-primary" routerLink="/login">{{ "learnMore" | i18n }}</a>
      </div>
    </ng-container>
    <ng-container slot="button">
      <button (click)="goToCreateNewLoginItem()" bitButton buttonType="primary" type="button">
        {{ "createNewLoginItem" | i18n }}
      </button>
    </ng-container>
  </bit-no-items>
</div>
<div class="tw-mt-4 tw-flex tw-flex-col" *ngIf="!(isLoading$ | async) && dataSource.data.length">
  <h2 class="tw-mb-6" bitTypography="h2">{{ "allApplications" | i18n }}</h2>
  <div class="tw-flex tw-gap-6">
    <tools-card
      #allAppsOrgAtRiskMembers
      class="tw-flex-1 tw-cursor-pointer"
      [ngClass]="{ 'tw-bg-primary-100': dataService.drawerInvokerId === 'allAppsOrgAtRiskMembers' }"
      [title]="'atRiskMembers' | i18n"
      [value]="applicationSummary.totalAtRiskMemberCount"
      [maxValue]="applicationSummary.totalMemberCount"
      (click)="showOrgAtRiskMembers('allAppsOrgAtRiskMembers')"
    >
    </tools-card>
    <tools-card
      #allAppsOrgAtRiskApplications
      class="tw-flex-1 tw-cursor-pointer"
      [ngClass]="{
        'tw-bg-primary-100': dataService.drawerInvokerId === 'allAppsOrgAtRiskApplications',
      }"
      [title]="'atRiskApplications' | i18n"
      [value]="applicationSummary.totalAtRiskApplicationCount"
      [maxValue]="applicationSummary.totalApplicationCount"
      (click)="showOrgAtRiskApps('allAppsOrgAtRiskApplications')"
    >
    </tools-card>
  </div>
  <div class="tw-flex tw-mt-8 tw-mb-4 tw-gap-4">
    <bit-search
      [placeholder]="'searchApps' | i18n"
      class="tw-grow"
      [formControl]="searchControl"
    ></bit-search>
    <button
      class="tw-rounded-lg"
      type="button"
      buttonType="secondary"
      bitButton
      *ngIf="isCriticalAppsFeatureEnabled"
      [disabled]="!selectedUrls.size"
      [loading]="markingAsCritical"
      (click)="markAppsAsCritical()"
    >
      <i class="bwi bwi-star-f tw-mr-2"></i>
      {{ "markAppAsCritical" | i18n }}
    </button>
  </div>
  <bit-table [dataSource]="dataSource">
    <ng-container header>
      <tr>
        <th *ngIf="isCriticalAppsFeatureEnabled"></th>
        <th bitSortable="applicationName" bitCell>{{ "application" | i18n }}</th>
        <th bitSortable="atRiskPasswordCount" bitCell>{{ "atRiskPasswords" | i18n }}</th>
        <th bitSortable="passwordCount" bitCell>{{ "totalPasswords" | i18n }}</th>
        <th bitSortable="atRiskMemberCount" bitCell>{{ "atRiskMembers" | i18n }}</th>
        <th bitSortable="memberCount" bitCell>{{ "totalMembers" | i18n }}</th>
      </tr>
    </ng-container>
    <ng-template body let-rows$>
      <tr
        bitRow
        *ngFor="let r of rows$ | async; trackBy: trackByFunction"
        [ngClass]="{ 'tw-bg-primary-100': dataService.drawerInvokerId === r.applicationName }"
      >
        <td *ngIf="isCriticalAppsFeatureEnabled">
          <input
            bitCheckbox
            type="checkbox"
            *ngIf="!r.isMarkedAsCritical"
            [checked]="selectedUrls.has(r.applicationName)"
            (change)="onCheckboxChange(r.applicationName, $event)"
          />
          <i class="bwi bwi-star-f" *ngIf="r.isMarkedAsCritical"></i>
        </td>
        <td class="tw-cursor-pointer" (click)="showAppAtRiskMembers(r.applicationName)" bitCell>
          <span>{{ r.applicationName }}</span>
        </td>
        <td bitCell>
          <span>
            {{ r.atRiskPasswordCount }}
          </span>
        </td>
        <td bitCell>
          <span>
            {{ r.passwordCount }}
          </span>
        </td>
        <td bitCell>
          <span>
            {{ r.atRiskMemberCount }}
          </span>
        </td>
        <td bitCell data-testid="total-membership">
          {{ r.memberCount }}
        </td>
      </tr>
    </ng-template>
  </bit-table>
</div>
