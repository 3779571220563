<div *ngIf="loading">
  <i
    class="bwi bwi-spinner bwi-spin tw-text-muted"
    title="{{ 'loading' | i18n }}"
    aria-hidden="true"
  ></i>
  <span class="tw-sr-only">{{ "loading" | i18n }}</span>
</div>
<div class="tw-mt-4" *ngIf="!dataSource.data.length">
  <bit-no-items [icon]="noItemsIcon" class="tw-text-main">
    <ng-container slot="title">
      <h2 class="tw-font-semibold mt-4">
        {{ "noCriticalAppsTitle" | i18n }}
      </h2>
    </ng-container>
    <ng-container slot="description">
      <p class="tw-text-muted">
        {{ "noCriticalAppsDescription" | i18n }}
      </p>
    </ng-container>
    <ng-container slot="button">
      <button (click)="goToAllAppsTab()" bitButton buttonType="primary" type="button">
        {{ "markCriticalApps" | i18n }}
      </button>
    </ng-container>
  </bit-no-items>
</div>
<div class="tw-mt-4 tw-flex tw-flex-col" *ngIf="!loading && dataSource.data.length">
  <div class="tw-flex tw-justify-between tw-mb-4">
    <h2 bitTypography="h2">{{ "criticalApplications" | i18n }}</h2>
    <button *ngIf="isNotificationsFeatureEnabled" bitButton buttonType="primary" type="button">
      <i class="bwi bwi-envelope tw-mr-2"></i>
      {{ "requestPasswordChange" | i18n }}
    </button>
  </div>
  <div class="tw-flex tw-gap-6">
    <tools-card
      #criticalAppsAtRiskMembers
      class="tw-flex-1 tw-cursor-pointer"
      [ngClass]="{
        'tw-bg-primary-100': dataService.drawerInvokerId === 'criticalAppsAtRiskMembers',
      }"
      [title]="'atRiskMembers' | i18n"
      [value]="applicationSummary.totalAtRiskMemberCount"
      [maxValue]="applicationSummary.totalMemberCount"
      (click)="showOrgAtRiskMembers('criticalAppsAtRiskMembers')"
    >
    </tools-card>
    <tools-card
      #criticalAppsAtRiskApplications
      class="tw-flex-1 tw-cursor-pointer"
      [ngClass]="{
        'tw-bg-primary-100': dataService.drawerInvokerId === 'criticalAppsAtRiskApplications',
      }"
      [title]="'atRiskApplications' | i18n"
      [value]="applicationSummary.totalAtRiskApplicationCount"
      [maxValue]="applicationSummary.totalApplicationCount"
      (click)="showOrgAtRiskApps('criticalAppsAtRiskApplications')"
    >
    </tools-card>
  </div>
  <div class="tw-flex tw-mt-8 tw-mb-4 tw-gap-4">
    <bit-search
      [placeholder]="'searchApps' | i18n"
      class="tw-grow"
      [formControl]="searchControl"
    ></bit-search>
  </div>
  <bit-table [dataSource]="dataSource">
    <ng-container header>
      <tr>
        <th></th>
        <th bitSortable="applicationName" bitCell>{{ "application" | i18n }}</th>
        <th bitSortable="atRiskPasswordCount" bitCell>{{ "atRiskPasswords" | i18n }}</th>
        <th bitSortable="passwordCount" bitCell>{{ "totalPasswords" | i18n }}</th>
        <th bitSortable="atRiskMemberCount" bitCell>{{ "atRiskMembers" | i18n }}</th>
        <th bitSortable="memberCount" bitCell>{{ "totalMembers" | i18n }}</th>
      </tr>
    </ng-container>
    <ng-template body let-rows$>
      <tr
        bitRow
        *ngFor="let r of rows$ | async; trackBy: trackByFunction"
        [ngClass]="{ 'tw-bg-primary-100': dataService.drawerInvokerId === r.applicationName }"
      >
        <td>
          <i class="bwi bwi-star-f" *ngIf="r.isMarkedAsCritical"></i>
        </td>
        <td class="tw-cursor-pointer" (click)="showAppAtRiskMembers(r.applicationName)" bitCell>
          <span>{{ r.applicationName }}</span>
        </td>
        <td bitCell>
          <span>
            {{ r.atRiskPasswordCount }}
          </span>
        </td>
        <td bitCell>
          <span>
            {{ r.passwordCount }}
          </span>
        </td>
        <td bitCell>
          <span>
            {{ r.atRiskMemberCount }}
          </span>
        </td>
        <td bitCell data-testid="total-membership">
          {{ r.memberCount }}
        </td>
        <td bitCell>
          <button
            [bitMenuTriggerFor]="rowMenu"
            type="button"
            bitIconButton="bwi-ellipsis-v"
            size="small"
            appA11yTitle="{{ 'options' | i18n }}"
          ></button>

          <bit-menu #rowMenu>
            <button type="button" bitMenuItem (click)="unmarkAsCriticalApp(r.applicationName)">
              <i aria-hidden="true" class="bwi bwi-star-f"></i> {{ "unmarkAsCriticalApp" | i18n }}
            </button>
          </bit-menu>
        </td>
      </tr>
    </ng-template>
  </bit-table>
</div>
